// Plugins CSS
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-bootstrap-switch";
@import "plugins/plugin-react-datetime";
// components
@import "vastavit-kit/dropdown";
@import "vastavit-kit/inputs";
@import "vastavit-kit/navbars";
@import "vastavit-kit/responsive";
@import "vastavit-kit/switch";
@import "vastavit-kit/tooltips-and-popovers";


.text-orange {
    color: #ee5c0a;
}

.content_txt {
    color: white;

    h2 {
        font-weight: 500;
    }

    h5 {
        font-weight: 500;
    }

    ul {
        list-style: none;

        li {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 15px;
            position: relative;
            padding-left: 20px;

            &::before {
                content: attr(data-label);
                position: absolute;
                left: 0;
                top: 0;

            }
        }
    }
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 132, 255, 0.3);
    background-color: rgba(0, 132, 255, 0.3);
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #02001b;
}

::-webkit-scrollbar-thumb {
    background-color: #006bf7;
}

.slick-list {
    height: 100vh;
}

.slick-track {
    height: 100vh;
}

.slick-slide>div {
    height: 100vh;

}

.sliderimg1 {
    background-image: url('../../img/antoine-barres.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        background-color: rgba(15, 12, 56, 0.575);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

.sliderimg2 {
    background-image: url('../../img/slider2.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        background-color: rgba(15, 12, 56, 0.575);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

.sliderimg3 {
    background-image: url('../../img/slider3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;

    &::before {
        content: '';
        background-color: rgba(15, 12, 56, 0.575);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

.sliderTitle {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;

    .titleContent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.highlight_content {
    ol {
        li{
        font-size: 18px;
        line-height: 27px;
        color: rgb(255, 255, 255);
        font-weight: 400;
        margin-bottom: 25px;
        }
    }
}

.colored_sec {
    background-color: #000;
}

// .fixed-top{
//     position: in !important;
//     // padding-top: 24px;
// }
.about_sec {
    padding: 0 0 50px 0;
    // height: 100vh;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    text-align: center;
    color: white;

    h4 {
        font-size: 30px;
        font-weight: 400;

        span {
            font-size: 16px;
        }
    }

    p {
        font-size: 19px;
        font-weight: 400;
    }

    img {
        width: 200px;
    }
}

.whyVastavContent {
    padding: 50px 0 50px 0;

    p {
        font-size: 19px;
        font-weight: 400;
        color: white;
    }

    ol {
        li {
            font-size: 16px;
            font-weight: 400;
            color: white;
        }
    }
}

.solution_sec {
    padding: 50px 0 100px;
}

.contact_sec {
    position: relative;
    padding: 80px 0;

    p {
        font-size: 25px;
        font-weight: 400;
    }

    .contactNumber {
        font-size: 25px;
    }

    .contact_wrp {
        border: solid 1px #1e1e1e;
        padding: 30px 30px;
        background-color: #121212;

        h5 {
            color: #00a2cf;
        }

        a {
            color: white;
        }

        .icon_wrp {
            i {

                position: relative;
                color: #000;
                width: 80px;
                height: 60px;

                &.fa-map-marker {
                    position: relative;

                    &::before {
                        content: "\f041";
                        position: absolute;
                        left: 31px;
                        font-size: 30px;
                        top: 10px;
                        color: #fff;
                        z-index: 1;
                    }
                }

                &.fa-envelope {
                    position: relative;

                    &::before {
                        content: "\f0e0";
                        position: absolute;
                        left: 30px;
                        font-size: 20px;
                        top: 15px;
                        color: #fff;
                        z-index: 1;
                    }
                }

                &.fa-phone-square {
                    position: relative;

                    &::before {
                        content: "\f098";
                        position: absolute;
                        left: 30px;
                        font-size: 23px;
                        top: 13px;
                        color: #fff;
                        z-index: 1;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 15px;
                    top: 0;
                    width: 50px;
                    height: 50px;
                    background-color: #3c3c3c;
                    border-radius: 100px;
                    z-index: 0;
                }

                @media (max-width:576px) {
                    display: none;
                }
            }
        }
    }
}

.mailText {
    font-size: 25px;
}

.products_sec {
    padding: 100px 0 0;
}

.prod_bg {
    background-image: url('../../img/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
        content: '';
        background-color: rgb(0 26 66 / 80%);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }
}


.lightbox-target {
    position: fixed;
    top: -100%;
    width: 100%;
    background: rgba(0, 0, 0, .7);
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow: hidden;
    left: 0;
    z-index: 9999;
}

/* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

.lightbox-target img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 0%;
    max-width: 0%;
    border: 1px solid white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

/* Styles the close link, adds the slide down transition */

a.lightbox-close {
    display: block;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    background: white;
    color: black;
    text-decoration: none;
    position: absolute;
    top: -80px;
    right: 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:before {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top: 10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:after {
    content: "";
    display: block;
    height: 30px;
    width: 1px;
    background: black;
    position: absolute;
    left: 26px;
    top: 10px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.lightbox-target p {
    bottom: 0px;
}

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

.lightbox-target:target {
    opacity: 1;
    top: 0;
    bottom: 0;
}

.lightbox-target:target img {
    max-height: 90%;
    max-width: 100%;
}

.lightbox-target:target a.lightbox-close {
    top: 0px;
}

.nav-item {
    a {
        &.active {
            p {
                color: #ee5c0a;
            }
        }
    }
}

.aboutBanner {
    background-image: url('../../img/slider3.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 500px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgb(30 28 58 / 59%);
    }

    .sliderText {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        h1 {
            text-transform: uppercase;
            background:linear-gradient(to bottom, #69f2f3 0%, #0098b0 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 100px;
            font-weight: bold;
        }
    }
}